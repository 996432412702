const baseStyle = {
    track: {
        bg: 'gray.300',
        _checked: {
            bg: 'primary.500',
        },
    },
};

const switchComponent = {
    baseStyle,
    defaultProps: {
        colorScheme: 'primary',
    },
};

export default switchComponent;
