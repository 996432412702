import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ICU from 'i18next-icu';

import admin from '@/assets/locales/fr/admin.json';
import common from '@/assets/locales/fr/common.json';
import dashboard from '@/assets/locales/fr/dashboard.json';
import contract from '@/assets/locales/fr/contract.json';
import importHistory from '@/assets/locales/fr/import-history.json';
import login from '@/assets/locales/fr/login.json';
import error from '@/assets/locales/fr/error.json';
import product from '@/assets/locales/fr/product.json';
import provider from '@/assets/locales/fr/provider.json';
import awaitingStoreValidation from '@/assets/locales/fr/awaiting-store-validation.json';
import service from '@/assets/locales/fr/service.json';
import store from '@/assets/locales/fr/store.json';
import changeContract from './../assets/locales/fr/change-contract.json';
import files from '@/assets/locales/fr/files.json';
import subscription from '@/assets/locales/fr/subscription.json';
import address from '@/assets/locales/fr/address.json';
import customers from '@/assets/locales/fr/customers.json';
import users from '@/assets/locales/fr/users.json';
import invitation from '@/assets/locales/fr/invitation.json';
import mails from '@/assets/locales/fr/mails.json';
import dataProviders from '@/assets/locales/fr/data-providers.json';
import storeInvoices from '@/assets/locales/fr/store-invoices.json';
import documentTemplates from '@/assets/locales/fr/document-templates.json';
import csvErrors from '@/assets/locales/fr/csv-errors.json';
import procedures from '@/assets/locales/fr/procedures.json';
import claims from '@/assets/locales/fr/claims.json';
import webhooks from '@/assets/locales/fr/webhooks.json';
import exportConfig from '@/assets/locales/fr/export-config.json';
import importConfig from '@/assets/locales/fr/import-config.json';
import fields from '@/assets/locales/fr/fields.json';
import productMatching from '@/assets/locales/fr/product-matching.json';
import websites from '@/assets/locales/fr/websites.json';
import paymentMeans from '@/assets/locales/fr/payment-means.json';

export type Namespaces =
    | 'admin'
    | 'login'
    | 'contract'
    | 'common'
    | 'dashboard'
    | 'error'
    | 'product'
    | 'provider'
    | 'store'
    | 'importHistory'
    | 'storeInvoices'
    | 'awaitingStoreValidation'
    | 'files'
    | 'subscription'
    | 'address'
    | 'customers'
    | 'users'
    | 'invitation'
    | 'mails'
    | 'dataProviders'
    | 'documentTemplates'
    | 'csvErrors'
    | 'procedures'
    | 'claims'
    | 'webhooks'
    | 'exportConfig'
    | 'importConfig'
    | 'changeContract'
    | 'fields'
    | 'productMatching'
    | 'websites'
    | 'paymentMeans';

export const defaultNS = 'common';
export const resources = {
    fr: {
        admin,
        login,
        contract,
        common,
        dashboard,
        error,
        product,
        provider,
        awaitingStoreValidation,
        service,
        store,
        importHistory,
        storeInvoices,
        files,
        subscription,
        address,
        customers,
        users,
        invitation,
        mails,
        dataProviders,
        documentTemplates,
        csvErrors,
        procedures,
        claims,
        webhooks,
        changeContract,
        exportConfig,
        importConfig,
        fields,
        productMatching,
        websites,
        paymentMeans,
    },
} as const;

i18n.use(ICU)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'fr',
        fallbackLng: 'fr',
        defaultNS,
        ns: [
            'admin',
            'login',
            'contract',
            'common',
            'company',
            'error',
            'product',
            'provider',
            'store',
            'importHistory',
            'storeInvoices',
            'awaitingStoreValidation',
            'files',
            'subscription',
            'address',
            'customers',
            'users',
            'invitation',
            'dataProviders',
            'documentTemplates',
            'csvErrors',
            'procedures',
            'claims',
            'webhooks',
            'exportConfig',
            'importConfig',
            'changeContract',
            'fields',
            'productMatching',
            'websites',
            'paymentMeans',
        ],
    });

export default i18n;
