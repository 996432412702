import React, { createContext, useCallback, useRef, useState } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ConfirmModalContext = createContext<
    (onConfirm: () => any, title?: string, description?: string) => any
>(() => null);

export function ConfirmModalProvider({ children }: React.PropsWithChildren<{}>) {
    const [confirmModal, setConfirmModal] = useState({
        isOpen: false,
        title: '',
        description: '',
        onConfirm: () => {},
    });
    const createConfirmModal = useCallback(
        (onConfirm: () => any, title?: string, description?: string) => {
            setConfirmModal({
                isOpen: true,
                title: title ?? '',
                description: description ?? '',
                onConfirm,
            });
        },
        [setConfirmModal],
    );

    const { t } = useTranslation(['common']);

    const handleClose = useCallback(() => {
        setConfirmModal({
            ...confirmModal,
            isOpen: false,
        });
    }, [setConfirmModal, confirmModal]);

    const cancelRef = useRef(null);

    return (
        <ConfirmModalContext.Provider value={createConfirmModal}>
            {children}
            <AlertDialog
                leastDestructiveRef={cancelRef}
                isOpen={confirmModal.isOpen}
                onClose={handleClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            {confirmModal.title || t('common:delete_confirm_modal_title')}
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            {confirmModal.description || t('common:delete_confirm_modal_content')}
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={handleClose}>
                                {t('common:cancel')}
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={() => {
                                    handleClose();
                                    confirmModal?.onConfirm();
                                }}
                                ml={3}
                            >
                                {t('common:delete')}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </ConfirmModalContext.Provider>
    );
}

export const useCreateConfirmModal = () => {
    return React.useContext(ConfirmModalContext);
};
