import { createContext, Provider, useContext } from 'react';

export const createGenericContext = <T extends unknown>(): readonly [
    () => T,
    Provider<T | undefined>,
] => {
    const genericContext = createContext<T | undefined>(undefined);

    const useGenericContext = () => {
        const context = useContext(genericContext);
        if (!context) {
            throw new Error('useGenericContext must be used within a Provider');
        }
        return context;
    };

    return [useGenericContext, genericContext.Provider];
};
