import { Api } from '@/generated/typing';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const api = new Api({
    baseURL: publicRuntimeConfig.apiBaseUrl,
});

export default api;
