const select = {
    variants: {
        outline: () => ({
            field: {
                bg: 'white',
                border: '2px',
                borderRadius: 'full',
                color: 'gray.700',
                borderColor: 'gray.100',
                _placeholder: { color: 'text3' },
            },
        }),
    },
};

export default select;
