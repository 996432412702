import { StoreDto } from '@/generated/typing';

import { createGenericContext } from '@/utils/create-generic-context';
import { ChakraProvider } from '@chakra-ui/react';
import { ReactNode, useMemo } from 'react';
import { useUserContext } from '@/contexts/user-context';
import { buildTheme } from '@/chakra/buildTheme';

type ThemeContextType = { activeStore?: StoreDto };

export const [useThemeContext, ThemeContextProvider] = createGenericContext<ThemeContextType>();

type Props = {
    children: ReactNode;
};
export const ThemeProvider = ({ children }: Props) => {
    const { activeStore } = useUserContext();

    const builtTheme = useMemo(
        () =>
            buildTheme(activeStore?.settings?.primaryColor, activeStore?.settings?.secondaryColor),
        [activeStore?.settings?.primaryColor, activeStore?.settings?.secondaryColor],
    );

    return (
        <ThemeContextProvider value={{ activeStore }}>
            <ChakraProvider theme={builtTheme}>{children}</ChakraProvider>
        </ThemeContextProvider>
    );
};
