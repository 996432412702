import { Namespaces } from '@/core/i18n';
import { useCallback, useMemo } from 'react';
import { useTranslation as useI18nTranslation } from 'react-i18next';
import { TOptions } from 'i18next';

interface INamespace {
    [key: string]: any;
}

export const useTranslation = (namespaces?: Namespaces[]) => {
    const translation = useI18nTranslation(namespaces);
    const language = useMemo(() => translation.i18n.language, [translation.i18n.language]);

    const translationExists = useCallback(
        (key?: string, ns?: string) => {
            if (!key || !ns) {
                return false;
            }
            const pathTranslation = key.split('.');
            if (pathTranslation.length > 1) {
                return pathTranslation.reduce(
                    (acc, current) => acc[current] ?? false,
                    translation.i18n.store.data[language][ns] as INamespace,
                );
            }

            return (translation.i18n.store.data[language][ns] as INamespace)[key] !== undefined;
        },
        [language, translation],
    );

    const translateError = useCallback(
        (errorMessage: string) => {
            let message = '';
            if (translationExists(errorMessage, 'error')) {
                message = translation.t(`error:${errorMessage}` as any);
            }
            return message;
        },
        [translation, translationExists],
    );

    const translateIfExists = useCallback(
        (stringToTranslate: string, options?: TOptions | string) => {
            const [ns, key] = stringToTranslate.split(':');
            return translationExists(key, ns) ? translation.t(`${ns}:${key}` as any, options) : key;
        },
        [translation, translationExists],
    );

    return { t: translation.t, translationExists, translateIfExists, translateError };
};
