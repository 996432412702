import React, { useRef, useState } from 'react';

export const useRefState = <T>(
    initialValue: T,
): [T, React.MutableRefObject<T>, (newValue: T) => void] => {
    const [value, _setValue] = useState<T>(initialValue);

    const ref = useRef(value);
    const setValue = (newValue: T) => {
        ref.current = newValue;
        _setValue(newValue);
    };

    return [value, ref, setValue];
};
