import { ReactNode, useCallback, useEffect } from 'react';
import api from '@/core/client';
import { signOut, useSession } from 'next-auth/react';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { createGenericContext } from '@/utils/create-generic-context';
import { Session } from '@/types/next-auth';
import { useRefState } from '@/hooks/useRefState';
import { OpenAPI } from '@/generated-hooks/requests';

type AuthContextType = { session: Session | null };

export const [useAuthContext, AuthContextProvider] = createGenericContext<AuthContextType>();

export const AuthProvider = ({ children }: { children: ReactNode }) => {
    const { data: _session } = useSession() as { data: Session | null };
    const [session, sessionRef, setSession] = useRefState(_session);
    const [, initRef, setInit] = useRefState(false);

    useEffect(() => {
        setSession(_session);
    }, [_session, setSession]);

    const disconnect = useCallback((error: AxiosError) => {
        if (error.response?.status === 401) {
            localStorage.clear();
            signOut({ callbackUrl: '/auth/login' });
        }
        throw error;
    }, []);

    const setBearer = useCallback(
        (config: InternalAxiosRequestConfig) => {
            if (sessionRef.current) {
                config.headers.set('Authorization', `Bearer ${sessionRef.current.token}`);
                OpenAPI.HEADERS = {
                    Authorization: `Bearer ${sessionRef.current.token}`,
                };
                return config;
            }
            return config;
        },
        [sessionRef],
    );

    useEffect(() => {
        if (!initRef.current) {
            setInit(true);
            api.instance.interceptors.request.use(setBearer);
            api.instance.interceptors.response.use(undefined, disconnect);
        }
    }, [initRef, setInit, setBearer, disconnect]);

    return <AuthContextProvider value={{ session }}>{children}</AuthContextProvider>;
};
