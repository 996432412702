import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    inputAnatomy.keys,
);

const baseStyle = definePartsStyle({
    field: {
        color: 'text1',
        _placeholder: { color: 'text1', opacity: 0.6 },
        fontWeight: 500,
    },
});

const variants = {
    outline: definePartsStyle(() => ({
        field: {
            bg: 'white',
            borderWidth: '1px',
            borderColor: 'gray.200',
            borderRadius: 'md',
            _hover: {
                borderColor: 'primary.500',
                // bg: 'gray.50',
            },
            _active: {
                borderColor: 'primary.500',
                outlineColor: 'primary.50',
                outlineWidth: '3px',
                outlineOffset: '0px',
                boxShadow: 'none',
            },
            _focus: {
                borderColor: 'primary.500',
                outlineColor: 'primary.50',
                outlineWidth: '3px',
                outlineOffset: '0px',
                boxShadow: 'none',
            },
            _invalid: {
                borderColor: 'red.400',
                outlineColor: 'red.alpha',
                outlineWidth: '3px',
                outlineOffset: '0px',
                boxShadow: 'none',
            },
            _placeholder: {
                opacity: 0.4,
            },
        },
        addon: {
            bg: 'gray.100',
            color: 'gray.500',
            border: 'none',
            fontWeight: 500,
            borderRadius: 'md',
        },
        element: {
            color: 'gray.500',
        },
    })),
    plain: definePartsStyle(() => ({
        field: {
            bg: 'gray.100',
            borderColor: 'transparent',
            borderWidth: '1px',
            color: 'text2',
            borderRadius: 'md',
            _hover: {
                bg: 'white',
                borderColor: 'primary.500',
            },
            _focus: {
                color: 'text1',
                bg: 'white',
                borderColor: 'primary.500',
                outlineColor: 'primary.50',
                outlineWidth: '3px',
                outlineOffset: '0px',
                boxShadow: 'none',
            },
            _invalid: {
                borderColor: 'red.400',
                outlineColor: 'red.alpha',
                outlineWidth: '3px',
                outlineOffset: '0px',
                bg: 'white',
            },
        },
    })),
    dark: definePartsStyle(() => ({
        field: {
            borderRadius: 'md',
            backdropFilter: 'blur(10px);',
            borderWidth: '1px',
            borderColor: 'gray.300',
            color: 'gray.100',
            bg: 'rgba(255,255,255,0.15)',
            _focus: {
                boxShadow: '0 0 10px 0 rgba(255, 255, 255, 0.7)',
            },
            _placeholder: {
                opacity: 0.5,
                color: 'white',
            },
        },
        element: {
            color: 'gray.100',
        },
    })),
};

const input = defineMultiStyleConfig({
    variants,
    baseStyle,
    defaultProps: {
        variant: 'outline',
    },
});

export default input;
