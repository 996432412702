import {
    Box,
    Icon,
    Text,
    ToastProps as ChakraToastProps,
    useToast as useChakraToast,
} from '@chakra-ui/react';
import { HiBadgeCheck, HiExclamation, HiInformationCircle, HiXCircle } from 'react-icons/hi';

const colorSchemesPerStatus = {
    success: 'green',
    error: 'red',
    warning: 'yellow',
    info: 'blue',
    loading: 'gray',
};

const iconPerStatus = {
    success: HiBadgeCheck,
    error: HiXCircle,
    warning: HiExclamation,
    info: HiInformationCircle,
    loading: HiBadgeCheck,
};

export type ToastProps = {
    title: string;
    status?: 'success' | 'error' | 'warning' | 'info';
    description?: string;
    details?: string;
};

const useToast = () => {
    const toast = useChakraToast();

    return (toastProps: ToastProps) => {
        toast({
            duration: 4000,
            isClosable: true,
            position: 'bottom',
            render: () => <CustomToast {...toastProps} />,
        });
    };
};

const CustomToast = ({
    description,
    title,
    status = 'success',
    details,
}: ToastProps & ChakraToastProps) => {
    const colorScheme = colorSchemesPerStatus[status];
    return (
        <Box
            sx={{
                p: 3,
                bg: `${colorScheme}.100`,
                borderRadius: 'md',
                display: 'flex',
                alignItems: 'flex-start',
                gap: 2,
                mt: -1,
                maxWidth: '400px',
            }}
        >
            <Icon as={iconPerStatus[status]} color={`${colorScheme}.500`} fontSize="24px" />
            <Box>
                <Text fontWeight="bold">{title}</Text>
                {description && <Text fontSize="md">{description}</Text>}
                {details && (
                    <Text fontSize="sm" color="text2" mt={1}>
                        {details}
                    </Text>
                )}
            </Box>
        </Box>
    );
};

export default useToast;
