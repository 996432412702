import Button from './button';
import Heading from './heading';
import Input from './input';
import FormLabel from './form-label';
import Form from './form';
import Select from './select';
import Table from './table';
import Switch from './switch';

const components = {
    Button,
    Heading,
    Input,
    FormLabel,
    Form,
    Select,
    Table,
    Switch,
};

export default components;
